import { codingEssentials, competitiveProgramming, computerVisionSpecialization, cppEssentials, cppHindi, deepLearningEssentials, dsaEssentials, dsaLevelUp, dynamicProgramming, gameTheory, gitAndGithub, graphAlgorithms, machineLearningEssentials, naturalLanguageProcessing, pythonDataScience, pythonProgramming } from "../assets"
import { teamData } from "./teamData"
import { whyCMData } from './whyCMData'

export const couponCode = "WINTERSALE23";
const currentPrice = "₹445+taxes ";

const cppHindiCourse = {
  courseImage: cppHindi,
  difficultyLevel: "BEGINNER TO ADVANCED 🔥🔥🔥",
  color: 'green1',
  title: "Mastering C++: From Basics to Advanced Programming (हिंदी)",
  mentors: "Mentor : Prteek Narang",
  starRating: "⭐⭐⭐⭐⭐ 4.3",
  isAvailable: true,
  slug: 'cpp-hindi',
  courseDetail: {
    title: "Mastering C++: From Basics to Advanced Programming (हिंदी)",
    descriptionTop: "Comprehensive & easy to understand course for aspiring programmers covering C++ Basics, STL, OOPS, and modern features",
    priceInfo: {
      current: currentPrice,
      original: "₹18,560",
    },
    udemyLink: `https://www.udemy.com/course/mastering-cplusplus-prateek-narang-hindi/?couponCode=${couponCode}`,
    starRating: "⭐⭐⭐⭐⭐ 4.3/5",
    youtubeIntro: "https://www.youtube.com/embed/8otR-IXvUrQ",
    learningOutcomes: [
      'Solid Foundations in C++',
      'Data Types, Control Structures, Loops',
      'Function Proefficiency',
      'OOP Mastery',
      'STL Expertise',
      'File I/O, Exception Handling',
      'Interactive Learning',
      'Quizzes, Projects, Assignments',
    ],
    courseDescription: [
      'Welcome to "Mastering C++," the ultimate course designed to empower you with the essential skills to become a proficient C++ programmer.',
      "Whether you're a complete beginner or already have some programming experience, this course will guide you through the programming basics, advanced features, and the intricacies of Object-Oriented Programming (OOP) in C++, leaving you well-prepared to tackle real-world programming challenges.",
      "Join us on this journey of mastering C++ and unlocking your full programming potential!",],
    courseFeatures: [
      "C++ programming basics, data types, control structures, efficient code",
      "Modularization, pass-by-value, pass-by-reference, creating robust programs",
      "Classes, objects, inheritance, encapsulation, polymorphism, secure applications.",
      "Containers, algorithms, vectors, sets, maps, efficient code.",
      "Templates, exception handling, file I/O, generic algorithms, flexibility.",
      "Quizzes, projects, theory-practice balance, responsive instructor team.",
      "Transformative journey to mastering C++ programming."
    ],

    courseDescriptionEnd: [
      "Discover a well-rounded learning experience—mixing theory with hands-on practice. Engage in interactive quizzes, tackle projects, and get guidance. Achieve mastery as you reinforce knowledge and track your progress."
    ],
    instructor: [teamData[0]],
    whyUs: whyCMData,
  },
}

export const courseData = [
  {
    id: 1,
    title: "Programming Languages",
    courses: [
      {
        courseImage: codingEssentials,
        difficultyLevel: "Beginner 🔥",
        color: 'green1',
        title: "Coding Essentials, Logic Building for Beginners",
        mentors: "Mentor : Prateek Narang",
        starRating: "⭐⭐⭐⭐⭐ 4.8",
        isAvailable: true,
        slug: 'coding-essentials',
        courseDetail: {
          title: "Coding Essentials, Logic Building for Beginners",
          descriptionTop: "Enter the world of programming, learn fundamental building blocks of programming & problem solving for beginners.",
          priceInfo: {
            current: "₹0",
            original: "₹18,560",
          },
          udemyLink: `https://www.udemy.com/course/learn-coding-for-beginners/?couponCode=${couponCode}`,
          starRating: "⭐⭐⭐⭐⭐ 4.8",
          youtubeIntro: "https://www.youtube-nocookie.com/embed/bN4erfb3cKw",
          learningOutcomes: [
            "Understand the building blocks of programming",
            "Identify how to break down a problem statement & build logical thinking mindset",
            "Concepts before you start learning programming languages and advanced topics",
            "Learn to illustrate the steps required to solve a problem",
          ],
          courseDescription: [
            "Are you an absolute beginner looking forward to kickstart your journey in the programming domain. Coding can be hard skill learn to learn for many but no more. Welcome to Coding Essentials, the most fundamental course that every aspiring programmer should take to kickstart their journey in the world of programming. The course teaches you the fundamental building blocks - before you start writing code in any programming language.",
            "You will understand the basic concepts and techniques to break down a given problem into smaller parts by drawing flowcharts, write pseudocode, and real code is also demonstrated using Python.",
            "The course is your first step towards problem solving, and will help you to get your basics right. After this course, you can easily pursue a course on learning a programming language of your choice, followed by Data Structures, Algorithms & Competitive Programming. Many top companies like Google, Facebook, Amazon, Directi, CodeNation, Goldman Sachs etc encourage Competitive Programming and conduct coding competitions to hire smart people who can solve problems.",
            "This course is taught by Prateek Narang, who is not just a software engineer but a teacher by heart. He has worked with companies like Google, and has co-founded two startups including Coding Minutes. He is currently working as Instructor & Engineer at Scaler, and has helped many students to achieve their dreams.",
          ],
          courseFeatures: [
            "HD Videos",
            "Intuitive Explanations",
            "Beginner Friendly Teaching",
          ],
          courseDescriptionEnd: [
            "This course is taught by Prateek Narang, who is not just a software engineer but a teacher by heart. He has worked with companies like Google, and has co-founded two startups including Coding Minutes. He is currently working as Instructor & Engineer at Scaler, and has helped many students to achieve their dreams.",
          ],
          instructor: [teamData[0]],
          whyUs: whyCMData,
        },
      },
      {
        courseImage: cppEssentials,
        difficultyLevel: "Beginner 🔥",
        color: 'green1',
        title: "C++ Programming Essentials for Beginners",
        mentors: "Mentor : Prateek Narang",
        starRating: "⭐⭐⭐⭐⭐ 4.7",
        isAvailable: true,
        slug: 'cpp-essentials',
        courseDetail: {
          title: "C++ Programming Essentials for Beginners",
          descriptionTop: "Beginner friendly course to learn C++ programming in a fun & easy way and enter the world of computer science!",
          priceInfo: {
            current: currentPrice,
            original: "₹18,560",
          },
          udemyLink: `https://www.udemy.com/course/c-plus-plus-programming-beginners/?couponCode=${couponCode}`,
          starRating: "⭐⭐⭐⭐⭐ 4.7",
          youtubeIntro: "https://www.youtube.com/embed/i0XRrAK1r2U",
          learningOutcomes: [
            "Programming Fundamentals & Concepts",
            "Complete Modern C++ Syntax",
            "C++ Standard Template Library",
            "Converting Logic into C++ Code",
            "Object Oriented Programming",
            "Problem Solving",
          ],
          courseDescription: [
            "Are you an absolute beginner looking forward to kickstart your journey in the programming domain. Coding can be hard skill learn to learn for many but no more. Welcome to C++ Programming Essentials, the most fundamental course that every aspiring programmer should take to kickstart their journey in the world of programming. The course teaches you the fundamental building blocks of programming and builds a logical thinking mindset using C++ as our programming language. Many concepts taught in the course are also relevant to other languages like Java, Python, JavaScript etc with few changes in the coding syntax.",
            "You will understand the basic concepts and techniques to break down a given problem into smaller parts by drawing flowcharts, write pseudocode, and then diving deep into C++ topics like - variables, datatypes, flow control using branching & loops, functions, arrays, character arrays & strings, recursion, bitmasking & OOPs concepts.",
          ],
          courseFeatures: [
            "HD Videos",
            "Intuitive Explanations",
            "Beginner Friendly Teaching",
            "Tested Industry vetted curriculum",
            "Assignments & Q-A Support",
            "Certificate of Completion",
          ],
          courseDescriptionEnd: [
            "The course is your first step towards problem solving, and will help you to get your basics right. After this course, you can easily pursue a course on learning a programming language of your choice, followed by Data Structures, Algorithms & Competitive Programming. Many top companies like Google, Facebook, Amazon, Directi, CodeNation, Goldman Sachs etc encourage algorithmic programming and conduct coding competitions to hire smart people who can solve problems.",
            "This course is taught by Prateek Narang, who is not just a software engineer but a teacher by heart and many bestseller courses on Udemy in Algorithms category! He has worked with companies like Google, and has co-founded two startups including Coding Minutes, which is now a part of Scaler Academy.",
          ],
          instructor: [teamData[0]],
          whyUs: whyCMData,
        },
      },
      cppHindiCourse,
      {
        courseImage: pythonProgramming,
        difficultyLevel: "BEGINNER TO INTERMEDIATE 🔥🔥",
        color: 'green1',
        title: "Python Programming Master course",
        mentors: "Mentor : Mohit Uniyal, Prateek Narang",
        starRating: "⭐⭐⭐⭐⭐ 5.0",
        isAvailable: true,
        slug: 'python-programming',
        courseDetail: {
          title: "Python Programming Master course",
          descriptionTop: "Hone your programming skills by learning Python3 - master data structures, OOPS, problem solving and project building!",
          priceInfo: {
            current: currentPrice,
            original: "₹18,560",
          },
          udemyLink: `https://www.udemy.com/course/python-programming-beginners-bootcamp/?couponCode=${couponCode}`,
          starRating: "⭐⭐⭐⭐⭐ 5.0",
          youtubeIntro: "https://www.youtube.com/embed/bN4erfb3cKw",
          learningOutcomes: [
            "Complete Python3 Programming",
            "Logic Building & Problem Solving",
            "Object Oriented Programming",
            "Inheritance & Polymorphism",
            "Intermediate - Advanced Python Concepts",
            "Modules & Packages",
            "File & Exception Handling",
            "30+ Algorithmic Problems",
            "Project - Markov Text Generation",
            "Project - URL Shortner",
          ],
          courseDescription: [
            "Welcome to Python Programming Master Course (2021) - the most comprehensive & robust Python3 Course by Coding Minutes designed for absolute beginners & developers!",
            "Python is the hottest programming language of the 21st century. It is widely in Machine Learning & Data Science. Many top companies like Instagram, Spotify, Netflix, Google, Dropbox use python extensively for their projects which includes building web apps, data pipelines, data analysis & visualisation, deep learning at scale and large scale distributed systems.",
            "This is the most comprehensive and detailed course for the Python programming language taught by Mohit Uniyal who is a data scientist turned instructor & Prateek Narang, a Google Engineer & algorithms instructor! Both the instructors have taught thousands of students in the last few years and have received amazing reviews. Whether you have never programmed before, already know basic syntax, or want to learn about the advanced features of Python, this course is for you!",
            "With over 200+ lectures and more than 18 hours of video, this course ensures you build a solid foundation in Python. This course includes quizzes, mock tests, coding exercises and practical projects - URL Shortener & Text Generation.",
            "This is a completely hands-on course, with interactive videos & coding in Jupyter Notebooks. You get the complete code repository to practice & revise on your machine.",
          ],
          courseFeatures: [
            "HD Videos",
            "Intuitive Explanations",
            "Beginner Friendly Teaching",
            "Tested Industry vetted curriculum",
            "Assignments & Q-A Support",
            "Certificate of Completion",
          ],
          courseDescriptionEnd: [
            "So what are you waiting for? Join Prateek & Mohit in this amazing journey, and start your journey of becoming a Python Champion today! Looking forward to see you in the course.",
          ],
          instructor: [teamData[1], teamData[0]],
          whyUs: whyCMData,
        },
      },
    ]
  },
  {
    id: 2,
    title: "Data Structures & Algorithms",
    courses: [
      {
        courseImage: dsaEssentials,
        difficultyLevel: "INTERMEDIATE 🔥🔥",
        color: 'yellow1',
        title: "Data Structures and Algorithms Essentials",
        mentors: "Mentor : Prateek Narang",
        starRating: "⭐⭐⭐⭐⭐ 4.7",
        isAvailable: true,
        slug: 'dsa-essentials',
        courseDetail: {
          title: "Data Structures and Algorithms Essentials",
          descriptionTop: "Master core computer science concepts by mastering data structures, algorithms & problem solving using C++",
          priceInfo: {
            current: currentPrice,
            original: "₹18,560",
          },
          udemyLink: `https://www.udemy.com/course/cpp-data-structures-algorithms-prateek-narang/?couponCode=${couponCode}`,
          starRating: "⭐⭐⭐⭐⭐ 4.7",
          youtubeIntro: "https://www.youtube.com/embed/bN4erfb3cKw",
          learningOutcomes: [
            "Core concepts & internals of Data Structures",
            "Trees, Linked Lists, Heaps, Graphs",
            "Build all data structures from scratch",
            "Object Oriented Programming Basics",
            "Learn to apply Data Structures",
            "Brute Force & Optimisation Techniques",
            "Space Time Complexity Analysis",
            "Hash Functions, Collision Handling",
            "Recursion & Backtracking",
            "Dynamic Memory & Pointers",
            "Create your own DS library!",
            "C++ Standard Template Library Basics",
            "Project - Design & Implement Shopping Cart",
          ],
          courseDescription: [
            "Are you a beginner looking to enter the world of Data Structures or intermediate programmer wondering what happens behind a Hash-table?",
            "Welcome to Data Structures & Algorithms, Essentials Course - the only course you need to understand the core concepts behind Data Structures & build a solid programming foundations using C++ . The course is taught by an expert instructor Prateek Narang from Google, who is not just a software engineer but also has mentored thousands of students in becoming great programmers & developers and is top rated on Udemy for his amazing teaching skills.",
            "Every software application revolves around data, performing different operations like Insert, Delete, Update & Search. To be a great software developer, understanding of Data Structures & Algorithms is must and this course provides you a deep understanding of the topic by covering both the theory and hands-on-implementation of each data structure from scratch.",
            "The Course contains 20+ hours of interactive video content & dozens of coding exercises, teaching you all essential concepts starting from ground zero. Each section covers data structure in great detail, with Coding Exercises & real life examples.",],
          courseFeatures: [
            "Bit masking",
            "Object Oriented Programming Basics",
            "Pointers & Dynamic Memory (C++)",
            "Recursion",
            "Array, 2D Array, Strings, Vectors",
            "Linked Lists, Stacks, Queues",
            "Trees, BST, Tries",
            "Heaps/Priority Queues",
            "Hash-tables, Collision Handling",
            "Graphs",
            "Brute Force, Backtracking",
            "Sorting & Searching",
            "Divide & Conquer",
            "Dynamic Programming",
          ],

          courseDescriptionEnd: [
            "The course is designed for beginner & intermediate programmers. We try to make not so easy topics look easy with intuitive explanations & interactive video lectures with dozens of memes ;) The course finishes with a final mini project - a command line app for an online shopping cart combining principles from Object Oriented Programming & Data Structures.",
          ],
          instructor: [teamData[0]],
          whyUs: whyCMData,
        },
      },
      {
        courseImage: dsaLevelUp,
        difficultyLevel: "ADVANCED 🔥🔥🔥",
        color: 'red1',
        title: "Data Structures & Algorithms Level up for Coding Interviews",
        mentors: "Mentor : Prateek Narang",
        starRating: "⭐⭐⭐⭐⭐ 4.7",
        isAvailable: true,
        slug: 'dsa-levelup',
        courseDetail: {
          title: "Data Structures & Algorithms Level up for Coding Interviews",
          descriptionTop: "Master problem solving for Coding Interviews, ace Data Structures & Algorithms by solving over 150 important questions!",
          priceInfo: {
            current: currentPrice,
            original: "₹18,560",
          },
          udemyLink: `https://www.udemy.com/course/cpp-data-structures-algorithms-levelup-prateek-narang/?couponCode=${couponCode}`,
          starRating: "⭐⭐⭐⭐⭐ 4.7",
          youtubeIntro: "https://www.youtube.com/embed/7KR3Yg29JOo",
          learningOutcomes: [
            "Accelerate your algorithmic thinking",
            "Using C++ Standard Template Library",
            "Learn to optimise brute force solutions",
            "Become a 10x Problem Solver",
            "Learn to apply Data Structures",
            "Sliding Window, Hashing, Maps and Sets",
            "Learn to write Complex Codes",
            "Master Dynamic Programming and Graphs",
            "150+ carefully Curated Problems",
            "BONUS : Build a LRU Cache",
            "Analysis of Space Time Complexity",
            "Practice Questions on All Important Topics",
          ],
          courseDescription: [
            'Do you find yourself feeling like you get "stuck" everytime you get a coding question?',
            "Welcome to Data Structures & Algorithms, Level up Course the only course that provides you an ultimate practice on problem solving process and helping you to take your data structures & algorithms to the next level. The course is taught by an expert instructor Prateek Narang from Google, who is not just a software engineer but also has mentored thousands of students in becoming great programmers & developers.",
            "The Course contains 25+ hours of interactive video content & dozens of coding exercises, teaching you the right tips & tricks in problem solving in a most concise way. Every problem discussion starts with a brute force approach, optimisations and ends with hands-on-coding video in C++ as well.",],
          courseFeatures: [
            "Arrays and Vectors",
            "Binary Search",
            "String Problems",
            "Recursion Problems",
            "Sliding Window Problem",
            "Linked List Problem",
            "Sorting and Searching",
            "Stack and Queue",
            "Binary Tree",
            "Graph Algorithms",
            "BFS & DFS",
            "Shortest Paths",
          ],
          courseDescriptionEnd: [
            "Course exercises are in C++ but programmers having experience in one or more languages (C++/Java/Python/JavaScript) can definitely do this course, provided they have fundamental understanding of data structures. The course covers both breadth & depth of topics, diving deep where-ever needed. You will also learn how to apply techniques involving like - sorting & searching algorithms, sliding window, binary search, hashing which are very important for problem solving. For advanced topics like Dynamic Programming & Graphs, the course starts from the basics & helps you master these topics from the very fundamentals.",
          ],
          instructor: [teamData[0]],
          whyUs: whyCMData,
        },
      },
      {
        courseImage: competitiveProgramming,
        difficultyLevel: "ADVANCED 🔥🔥🔥",
        color: 'red1',
        title: "Competitive Programming Essentials, Master Algorithms",
        mentors: "Mentor : Prateek Narang, Apaar Kamal",
        starRating: "⭐⭐⭐⭐⭐ 4.7",
        isAvailable: true,
        slug: 'competitive-programming',
        courseDetail: {
          title: "Competitive Programming Essentials, Master Algorithms",
          descriptionTop: "Master competitive coding techniques - maths, number theory, dynamic programming, advanced data structures & algorithms",
          priceInfo: {
            current: currentPrice,
            original: "₹18,560",
          },
          udemyLink: `https://www.udemy.com/course/competitive-programming-algorithms-coding-minutes/?couponCode=${couponCode}`,
          starRating: "⭐⭐⭐⭐⭐ 4.7",
          youtubeIntro: "https://www.youtube.com/embed/JDwiZkjR38M",
          learningOutcomes: [
            "Understand & implement important techniques in Competitive Programming",
            "Learn advanced techniques to optimise naive solutions",
            "Ace code-thons and online coding competitons on Codeforces, HackerRank",
            "Get ready for ACM-ICPC, Google Kickstart, CodeJam & more",
          ],
          courseDescription: [
            'Equip yourself with essential programming techniques required for ACM-ICPC, Google CodeJam, Kickstart, Facebook HackerCup & more. Welcome to Competitive Programming Essentials - the ultimate specialisation on Algorithms for Competitive Coders!',
            "The online Competitive Programming Essentials by Coding Minutes is a highly exhaustive & rigorous course on Competitive Programming. The 50+ hours course covers the breadth & depth of algorithmic programming starting from a recap of common data structures, and diving deep into essential and advanced algorithms.",
            "The course structure is well-researched by instructors who not only Competitive Coders but have worked with companies like Google & Scaler. This course will help you to get a solid grip of fundamental concepts & comes with practice questions so that you sail through online coding challenges and code-athons with ease. The course is divided into 10 modules and 50 sections covering topics like Mathematics, Number Theory, Bitmasking, Inclusion-Exclusion, Meet in the Middle Techniques, Segment Trees, Fenwick Trees, Square Root Decomposition, Graph Algorithms, Shortest Paths, Game Theory, Pattern Matching, Binary Search, Greedy Techniques, Dynamic Programming and even more.",
            "The problem setters of the course are Siddharth Singhal and Rajdeep Singh. Both are upcoming software developers at Microsoft and Razorpay respectively. They both exhibit excellent knowledge of Data Structures and Algorithms and are avid competitive programmers.",
            "Many top companies like Google, Facebook, Amazon, Directi, CodeNation, Goldman Sachs etc encourage Competitive Programming and conduct coding competitions to hire smart people who can solve problems.",],
          courseFeatures: [

            "Instructors from Google & Scaler Academy",
            "50+ hours of high quality & structured content",
            "In-depth coverage of all topics",
            "Exhaustive Course Curriculum",
            "Code Evaluation on Coding Exercises",
            "Lifetime Access",
          ],
          courseDescriptionEnd: [
            " ",
          ],
          instructor: [teamData[0], teamData[2]],
          whyUs: whyCMData,
        },
      },
    ]
  },
  {
    id: 3,
    title: "Specialisations",
    courses: [
      {
        courseImage: graphAlgorithms,
        difficultyLevel: "BEGINNER TO ADVANCED 🔥🔥🔥",
        color: 'green1',
        title: "Graph Algorithms for Competitive Coding",
        mentors: "Mentor : Prateek Narang, Apaar Kamal",
        starRating: "⭐⭐⭐⭐⭐ 4.8",
        isAvailable: true,
        slug: 'graph-algorithms',
        courseDetail: {
          title: "Graph Algorithms for Competitive Coding",
          descriptionTop: "Learn Graphs Algorithms in Computer Science & Mathematics, theory + hands-on coding and ace Competitive Coding problems!",
          priceInfo: {
            current: currentPrice,
            original: "₹18,560",
          },
          udemyLink: `https://www.udemy.com/course/graph-theory-algorithms-for-competitive-programming/?couponCode=${couponCode}`,
          starRating: "⭐⭐⭐⭐⭐ 4.8",
          youtubeIntro: "https://www.youtube.com/embed/H4G7LJ-muLA",
          learningOutcomes: [
            "Graph Basics, Applications",
            "BFS, DFS, Connected Components",
            "Shortest Paths - Dijkstra, Bellman, Floyd Warshall",
            "Travelling Salesman Problem - DP with Bitmasks",
            "Topological Ordering, Strongly Connected Components",
            "Disjoint Set Union, Minimum Spanning Trees, Prim's & Kruskal",
            "Advanced Graphs, Euler Tour, Trees",
            "Network Flow, LCA, Articulation Points",
            "Graphs for Competitive Programming",
            "80 + Competitive Coding Questions",
            "Complete Code Repository in C++ and Java",
            "Coding Exercises Solutions",
          ],
          courseDescription: [
            "Welcome to Graph Algorithms for Competitive Coding - the most detailed Specialisation in Graph Theory for Competitive Programmers, Software Engineers & Computer Science students!",
            "Graphs is quite an important topic for software engineers, both for academics & online competitions and for solving real life challenges. Graph algorithms form the very fundamentals of many popular applications like - Google Maps, social media apps like Facebook, Instagram, Quora, LinkedIn, Computer Vision applications such as image segmentation, resolving dependencies while compile time, vehicle routing problems in supply chain and many more. This course provides a detailed overview of Graph Theory algorithms in computer science, along with hands on implementation of all the algorithms in C++. Not just that you will get 80+ competitive coding questions, to practice & test your skills!",
            "This comprehensive course is taught by Prateek Narang & Apaar Kamal, who are Software Engineers at Google and have taught over thousands of students in competitive programming over last 5+ years. This course is worth thousands of dollars, but Coding Minutes is providing you this course to you at a fraction of its original cost! This is action oriented course, we not just delve into theory but focus on the practical aspects by building implementing algorithms & solving problems. With over 95+ high quality video lectures, easy to understand explanations this is one of the most detailed and robust course for Graph Algorithms ever created.",
            "Course starts very basics with how to store and represent graphs on a computer, and then dives into popular algorithms & techniques for problem solving. The course is divided into two parts.",],
          courseFeatures: [
            "Graph Representations",
            "Popular Traversals - BFS & DFS",
            "Cycle Detection - Weighted & Unweighted Graphs",
            "Topological Ordering & Directed Acyclic Graphs",
            "Disjoint Set Union, Path Compression & Union by Rank",
            "Minimum Spanning Trees - Prim's & Kruskal's",
            "Shortest Paths - BFS, Dijkstra's, Bellman Ford, Floyd Warshall",
            "Travelling Salesman Problem, Min Cost Hamiltonian Cycle",
            "Flood Fill",
            "Multisource BFS",
            "DFS & Backedges",
            "SCC's & Kosaraju's Algorithm",
            "Euler Tour",
            "LCA",
            "Trees",
            "Articulation Points & Bridges",
            "Network Flow",
          ],
          courseDescriptionEnd: [
            "The part-II is recommended for programmers who want to deep dive into Competitive Programming & take part in contests. For most students part-I is good enough to understand the most fundamental concepts and techniques in graphs!",
          ],
          instructor: [teamData[0], teamData[2]],
          whyUs: whyCMData,
        },
      },
      {
        courseImage: gameTheory,
        difficultyLevel: "INTERMEDIATE TO ADVANCED 🔥🔥🔥",
        color: 'yellow1',
        title: "Game Theory Algorithms for Competitive Programming",
        mentors: "Mentor : Apaar Kamal",
        starRating: "⭐⭐⭐⭐⭐ 5.0",
        isAvailable: true,
        slug: 'game-theory-algorithms',
        courseDetail: {
          title: "Game Theory Algorithms for Competitive Programming",
          descriptionTop: "Dive deep into game theory algorithms, learn & apply these techniques for Codeforces & Competitive Programming problems!",
          priceInfo: {
            current: currentPrice,
            original: "₹18,560",
          },
          udemyLink: `https://www.udemy.com/course/game-theory-algorithms-in-competitive-programming-course/?couponCode=${couponCode}`,
          starRating: "⭐⭐⭐⭐⭐ 5.0",
          youtubeIntro: "https://www.youtube.com/embed/bN4erfb3cKw",
          learningOutcomes: [
            "Learn in-depth Game Theory Concepts",
            "Learn to solve Competitive Programming Questions",
            "Problems involving Nim Game, Sprague-Grundy Theorem",
            "Combinatorial Games, Subtraction Games, Games as Graphs",
            "Learn by solving Easy, Medium & Hard problems",
            "Hands-on Coding for every Game Theory Challenge",
          ],
          courseDescription: [
            "This hands-on course is designed for everyone to learn & implement Game Theory concepts to solve Competitive Programming Challenges. You will learn how approach Game Theory based questions involving - Nim Game, Sprague Grundy Theorem, Subtraction Games, Combinatorial Games, Graph Games, Take-away games! The course involves both breadth and depth of these topics with enough examples and hands-on coding for each problem.",
            "Competitive programming or Sport Programming is a mind sport usually, involving participants trying to program according to provided specifications. Competitive programming is recognised and supported by several multinational software and Internet companies, such as Google and Facebook. Popular Competitive Programming platforms include Codeforces, Codechef, HackerEarth, HackerRank, Spoj and more! This course is designed for both beginners and advanced programmers looking forward to take the next leap in Competitive Programming!",
            "Participation in programming contests can increase student enthusiasm for computer science studies. The skills acquired in ICPC-like programming contests also improve career prospects, which often require candidates to solve complex programming and algorithmic problems on the spot.",
            "This comprehensive course is taught by Apaar Kamal, who is a highly successful competitive coder and popular bootcamp Udemy Instructor and has taught thousands of students in several online and in-person courses over last 2+ years. This is deep-dive course, we not just delve into theory but focus on the practical aspects by solving multiple game theory problems of various difficulty levels.",
            "The course starts with basics of Game Theory and then diving deeper topics! Here are some of the topics that you will learn in this course.",],
          courseFeatures: [
            "Combinatorial Games",
            "Take-away Games",
            "N/P Positions",
            "Game of Nim",
            "Nim-Sum",
            "Applications of Nim-Sum",
            "Similar Nim-Games",
            "Games as Graphs",
            "Sprague Grundy Function",
            "20+ Problems with Code",

          ],
          courseDescriptionEnd: [
            " ",


          ],
          instructor: [teamData[2]],
          whyUs: whyCMData,
        },
      },
      {
        courseImage: dynamicProgramming,
        difficultyLevel: "BEGINNER TO EXPERT 🔥🔥🔥",
        color: 'green1',
        title: "Dynamic Programming Master Course",
        mentors: "Mentor : Prateek Narang, Apaar Kamal",
        starRating: "⭐⭐⭐⭐⭐ 4.7",
        isAvailable: true,
        slug: 'dynamic-programming',
        courseDetail: {
          title: "Dynamic Programming Master Course",
          descriptionTop: "Ultimate Specialisation on Recursion, Backtracking and Dynamic Programming for Competitive Coding & Interviews.",
          priceInfo: {
            current: currentPrice,
            original: "₹18,560",
          },
          udemyLink: `https://www.udemy.com/course/dynamic-programming-master-course-coding-minutes/?couponCode=${couponCode}`,
          starRating: "⭐⭐⭐⭐⭐ 4.7",
          youtubeIntro: "https://www.youtube.com/embed/bN4erfb3cKw",
          learningOutcomes: [
            "Identify what type of problems can be solved using DP",
            "Come with recurrence for most DP Problems",
            "Master the advance DP techniques",
            "Face interviews and online challenges more confidently",
          ],
          courseDescription: [
            "Are you feeling brushed-up with data structures and algorithms but often feel stuck when it comes to DP! But no more, we are super happy to present the Dynamic Programming Specialisation Course for everyone, to help you master the important Dynamic Programming Concepts and level up for Competitive Coding & Interviews.",
            "The Dynamic Programming Master Course is taught by Apaar Kamal, software engineer at Google & Master on Codeforces along with Prateek Narang, an ex-Google engineer and founder of Coding Minutes. This highly detailed & rigorous curriculum has been designed to ensure your success in online competitions where Dynamic Programming problems are very common these days. Many top companies like Google, Facebook, Amazon, Directi, CodeNation, Goldman Sachs etc encourage Competitive Programming and conduct coding competitions to hire smart people who can solve problems.",
            "Dynamic Programming is a popular algorithmic technique in computer programming that helps to efficiently solve a class of problems that have overlapping subproblems and optimal substructure property.",
            "If any problem can be divided into subproblems, which in turn are divided into smaller subproblems, and if there are overlapping among these subproblems, then the solutions to these subproblems can be saved for future reference. In this way, algorithm can be improved, and lot of problems can be optimised using Dynamic Programming.",
            "The 40+ hours course covers the breadth & depth of dynamic programming starting from a recap of recursion, backtracking, and diving deep into Dynamic Programming techniques like -",],
          courseFeatures: [
            "1D, 2D, Multi-dimensional DP",
            "DP and Partition Problems",
            "DP for Combinatorics & Subsequences",
            "DP on Strings",
            "Digit DP",
            "DP on Trees & Graphs",
            "SOS DP and",
            "DP with Bitmasks",
            "DP and Matrix Exponentiation",
            "DP in Game Theory",
            "DP with advanced data structures",

          ],
          courseDescriptionEnd: [
            " ",
          ],
          instructor: [teamData[0], teamData[2]],
          whyUs: whyCMData,
        },
      },
    ]
  },
  {
    id: 4,
    title: "Machine Learning",
    courses: [
      {
        courseImage: pythonDataScience,
        difficultyLevel: "BEGINNER TO ADVANCED 🔥🔥🔥",
        color: 'green1',
        title: "Python for Data Science Master Course",
        mentors: "Mentor : Mohit Uniyal",
        starRating: "⭐⭐⭐⭐⭐ 4.7",
        isAvailable: true,
        slug: 'python-datascience',
        courseDetail: {
          title: "Python for Data Science Master Course",
          descriptionTop: "Level up in Data Science using Python, master Numpy, Pandas, Data Visualisation, Web Scraping, Automation, SQL & more.!",
          priceInfo: {
            current: currentPrice,
            original: "₹18,560",
          },
          udemyLink: `https://www.udemy.com/course/python-data-science-master-course/?couponCode=${couponCode}`,
          starRating: "⭐⭐⭐⭐⭐ 4.7",
          youtubeIntro: "https://www.youtube.com/embed/ILXTx4nlbJk",
          learningOutcomes: [
            "Python fundamentals for beginners!",
            "Learn to use Python for Data Science",
            "Data Acquistion using Beautiful Soup, Scrapy",
            "Automation using Selenium",
            "Data Analysis using Numpy, Pandas, SQL",
            "Data Visualisation using Seaborn, Matplotlib",
            "Introduction to Machine Learning",
            "Building 5 projects using data science concepts",
          ],
          courseDescription: [
            "Are you ready to take the next leap in your journey to become a Data Scientist?",
            "This hands-on course is designed for absolute beginners as well as for proficient programmers who want to use the Python for solving real life problems. You will learn how analyse data, make interesting data visualisations, drive insights, scrape web, automate boring tasks and working with databases using SQL.",
            "Data Science has one of the most rewarding jobs of the 21st century and fortune-500 tech companies are spending heavily on data scientists! Data Science as a career is very rewarding and offers one of the highest salaries in the world. This course is designed for both beginners with some programming experience or experienced developers looking to enter the world of Data Science!",
            "This comprehensive course is taught by Mohit Uniyal, who is a popular Data Science Bootcamp instructor in India and has taught thousands of students in several online and in-person courses over last 3+ years. This course is worth thousands of dollars, but Coding Minutes is providing you this course to you at a fraction of its original cost! This is action oriented course, we not just delve into theory but focus on the practical aspects by building 5 projects. With over 150+ High Quality video lectures, easy to understand explanations and complete code repository this is one of the most detailed and robust course for learning data science.",
            "The course starts with basics of Python and then diving deeper into data science topics! Here are some of the topics that you will learn in this course.",],
          courseFeatures: [
            "Programming with Python",
            "Numeric Computation using NumPy",
            "Data Analysis using Pandas",
            "Data Visualisation using Matplotlib",
            "Data Visualisation using Seaborn",
            "Fetching data from Web API's",
            "Data Acquisition",
            "Web Scraping using Beautiful Soup",
            "Building a Web Crawler using Scrapy",
            "Automating boring stuff using Selenium",
            "Language of Databases - SQL!",
            "Introduction to Machine Learning",
          ],
          courseDescriptionEnd: [
            " ",

          ],
          instructor: [teamData[1]],
          whyUs: whyCMData,
        },
      },
      {
        courseImage: machineLearningEssentials,
        difficultyLevel: "BEGINNER TO ADVANCED 🔥🔥🔥",
        color: 'green1',
        title: "Machine Learning Essentials (2023)",
        mentors: "Mentor : Mohit Uniyal, Prateek Narang",
        isAvailable: true,
        starRating: "⭐⭐⭐⭐⭐ 4.5",
        slug: 'machine-learning-essentials',
        courseDetail: {
          title: "Machine Learning Essentials (2023)",
          descriptionTop: "Kickstart Machine Learning, understand maths behind essential algorithms, implement them in python & build 8+ projects!",
          priceInfo: {
            current: currentPrice,
            original: "₹18,560",
          },
          udemyLink: `https://www.udemy.com/course/machine-learning-artificial-intelligence-essentials/?couponCode=${couponCode}`,
          starRating: "⭐⭐⭐⭐⭐ 4.5",
          youtubeIntro: "https://www.youtube.com/embed/ILXTx4nlbJk",
          learningOutcomes: [
            "Jumpstart the world of AI & ML",
            "Regression & Classification Techniques",
            "K-Nearest Neighbours, K-Means",
            "Decision Trees & Random Forests",
            "Dimensionality Reduction",
            "8+ Hands on Projects",
            "Maths of Machine Learning",
            "Linear & Logistic Regression",
            "Naive Bayes, Text Classification",
            "Ensemble Learning - Bagging & Boosting",
            "Neural Networks",
          ],
          courseDescription: [
            "Read to jumpstart the world of Machine Learning & Artificial intelligence?",
            "This hands-on course is designed for absolute beginners as well as for proficient programmers who want kickstart Machine Learning for solving real life problems. You will learn how to work with data, and train models capable of making \"intelligent decisions\"",
            "Data Science has one of the most rewarding jobs of the 21st century and fortune-500 tech companies are spending heavily on data scientists! Data Science as a career is very rewarding and offers one of the highest salaries in the world. Unlike other courses, which cover only library-implementations this course is designed to give you a solid foundation in Machine Learning by covering maths and implementation from scratch in Python for most statistical techniques.",
            "This comprehensive course is taught by Prateek Narang & Mohit Uniyal, who not just popular instructors but also have worked in Software Engineering and Data Science domains with companies like Google. They have taught thousands of students in several online and in-person courses over last 3+ years.",
            "We are providing you this course to you at a fraction of its original cost! This is action oriented course, we not just delve into theory but focus on the practical aspects by building 8+ projects.",
            "With over 170+ high quality video lectures, easy to understand explanations and complete code repository this is one of the most detailed and robust course for learning data science."
          ],
          courseFeatures: [
            "Logistic Regression",
            "Linear Regression",
            "Principal Component Analysis",
            "Naive Bayes",
            "Decision Trees",
            "Bagging and Boosting",
            "K-NN",
            "K-Means",
            "Neural Networks",
            "Convex Optimisation",
            "Overfitting vs Underfitting",
            "Bias Variance Tradeoff",
            "Performance Metrics",
            "Data Pre-processing",
            "Feature Engineering",
            "Parametric vs Non-Parametric Techniques",
            "Working with numeric data, images & textual data",

          ],
          courseDescriptionEnd: [
            "Sign up for the course and take your first step towards becoming a machine learning engineer! See you in the course!",

          ],
          instructor: [teamData[1], teamData[0]],
          whyUs: whyCMData,
        },
      },
      {
        courseImage: deepLearningEssentials,
        difficultyLevel: "Intermediate to Advanced 🔥🔥🔥",
        color: 'yellow1',
        title: "Deep Learnings Essentials",
        mentors: "Mentor : Prateek Narang, Mohit Uniyal",
        isAvailable: false,
        starRating: "",
        slug: 'deep-learning-essentials',
        courseDetail: {}
      },
      {
        courseImage: computerVisionSpecialization,
        difficultyLevel: "Intermediate to Advanced 🔥🔥🔥",
        color: 'yellow1',
        title: "Computer Vision Specialisation",
        mentors: "Mentor : Prateek Narang, Mohit Uniyal",
        isAvailable: false,
        starRating: "",
        slug: 'computer-vision-specialisation',
        courseDetail: {}
      },
      {
        courseImage: naturalLanguageProcessing,
        difficultyLevel: "Intermediate to Advanced 🔥🔥🔥",
        color: 'yellow1',
        title: "Natural Language Processing",
        mentors: "Mentor : Prateek Narang, Mohit Uniyal",
        isAvailable: false,
        starRating: "",
        slug: 'natural-language-processing',
        courseDetail: {}
      },
    ]
  },
  {
    id: 5,
    title: "Software Engineering",
    courses: [
      {
        courseImage: gitAndGithub,
        difficultyLevel: "BEGINNER TO ADVANCED 🔥🔥🔥",
        color: 'green1',
        title: "Git & GitHub Master Course (2023)",
        mentors: "Mentor : Mohit Uniyal",
        starRating: "⭐⭐⭐⭐⭐ 4.8",
        isAvailable: true,
        slug: 'git-and-github',
        courseDetail: {
          title: "Git & GitHub Master Course (2022)",
          descriptionTop: "Master the most essential tools for software development, everything about Git, Github & Version Control.",
          priceInfo: {
            current: currentPrice,
            original: "₹18,560",
          },
          udemyLink: `https://www.udemy.com/course/git-github-master-course/?couponCode=${couponCode}`,
          starRating: "⭐⭐⭐⭐⭐ 4.8",
          youtubeIntro: "https://www.youtube.com/embed/xT4kaFXpmD4",
          learningOutcomes: [
            "Understand Version Control Systems",
            "Master Git, the most popular VCS",
            "Git Workflow - Staging area, git commits, git diff",
            "Branching - Creating and Merging Branches",
            "Deploying a Git Repository on Github",
            "Using Github Pages to deploy websites",
            "How to collaborate as a team",
            "Moving back and forth in git timeline",
            "Advanced Git Features - Rebasing, Stashing, Branching, Merge Conflicts",
            "Project Work - Create a Web Portfolio",
          ],
          courseDescription: [
            "If you are curious to know how big tech giants manage multiple versions of their projects? In this course, you will learn everything related to version control systems.",
            "Welcome to the Git & Github Master course (2021). This course is designed for absolute beginners who have no experience in project building and developers who already are working on projects, but want to upskill with the advanced features of git.",
            "Hi, My name is Mohit Uniyal, I'm a data scientist and a programming instructor. I co-created Coding Minutes to help students & developers like you to build a solid foundation & become great programmers.",],
          courseFeatures: [
            "Version Control Systems",
            "Command Line Tool",
            "Git Introduction",
            "Tracking your projects",
            "Additional Git commands",
            "Non-linear Development: Branching",
            "Non-linear Development: Merging",
            "Non-linear Development: Rebasing",
            "Introduction to Github",
            "Social Coding with Github",
            "Miscellaneous Git Tools",
            "Rewriting History",
            "Project Work : Creating your portfolio website",

          ],

          courseDescriptionEnd: [
            "No matter whether you are a student, web developer, mobile developer, data scientist or just a computer programmer. Everyone should have Git & Github in their toolkit. This course the only course you need to master Git & Github. The course covers everything in a practical hands-on manner. You will get a quiz in each section to test your understanding of the topics.",
          ],
          instructor: [teamData[1]],
          whyUs: whyCMData,
        },
      },
    ]
  },
  {
    id: 6,
    title: 'Courses in Hindi',
    courses: [
      cppHindiCourse
    ]
  }

]
